<footer class="{{mode_html == true ? 'dark': ''}} footer-quality">
    <div class="row m-0 p-0">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-inline-flex">
            <nav class="footer-nav">
                <ul class="footer-nav-list">
                    <li class="nav-link">
                        <a class="footer-text">Qualitysoftgroup</a>
                    </li>
                    <!-- <li class="nav-link">
                        <a class="footer-text">Licencia</a>
                    </li> -->
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright footer-text">
                    © {{test | date: 'yyyy'}}, creado por Qualitysoftgroup <fa-icon [icon]="footerIcon"></fa-icon>
                </span>
            </div>
        </div>
    </div>
</footer>
