import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import {AppComponent} from '../app.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  public mode_html:boolean = true;
  private modeHtmlObservable:any;
  public viewnotpage:boolean = false;


  constructor(private appfuncion:AppComponent, private title:Title, private seo:SeoService, private route: ActivatedRoute, private router:Router) { }

  ngOnInit(): void {
    this.mode_html = this.appfuncion.mode_html;
    this.modeHtmlObservable = this.appfuncion.getmode_html$().subscribe((mode_html:boolean)=>{
      this.mode_html = mode_html;
    });

    if(this.router.url.indexOf('qualitylab') >= 0 && this.router.url.indexOf('.php') < 0 && this.router.url.indexOf('?') >= 0 && this.router.url.indexOf('resultadopdf.php') < 0){
      this.viewnotpage = false;
      document.querySelector('body').classList.remove('dark');
      document.querySelector('nav').hidden = true;
      this.route.url.subscribe(queryParams => {
        if(queryParams[1]){
          let parametrosUrl:Map<string, any> = null;
          let urlretorno = `?`;
          let entre = false;
          this.route.queryParams.forEach( (parametros) => {
            parametrosUrl = new Map(Object.entries(parametros));
            parametrosUrl.forEach((data, key)=>{
              if(!entre) urlretorno += `${key}=${data}`;
              if(entre) urlretorno += `&${key}=${data}`;
              entre = true;
            });
          });
          return window.location.href = `qualitylab/${queryParams[1].path}/index.php${urlretorno}`;
        }
      });
    }else if(this.router.url.indexOf('qualitylab') >= 0 && this.router.url.indexOf('.php') < 0){
      this.viewnotpage = false;
      document.querySelector('body').classList.remove('dark');
      document.querySelector('nav').hidden = true;
      this.route.url.subscribe(queryParams => {
        if(queryParams[1]) return window.location.href = `qualitylab/${queryParams[1].path}/index.php`;
        return window.location.href = 'qualitylab/index.php';
      });
    }else if(this.router.url.indexOf('qualitylab') >= 0 && this.router.url.indexOf('resultadopdf.php') >= 0){
      this.viewnotpage = false;
      document.querySelector('body').classList.remove('dark');
      document.querySelector('nav').hidden = true;
      this.route.url.subscribe(queryParams => {
        if(queryParams[1] && !this.route.root.snapshot.queryParams['reload']) return window.location.href = `${this.route.root.snapshot['_routerState'].url}&reload=true`;
      });
    }else{
      this.viewnotpage = true;

      let t:string = 'upss!! 404';
      this.title.setTitle(t);

      this.seo.generateTags({
        title: 'Nuestros prodcutos',
        description: 'Nuestros prodcutos',
        image: '../assets/img/software/asesorias.svg',
        slug: 'nuestros-prodcutos',
        keywords: 'prodcutos qualitysoft group, desarrollo de software, asesorias, diseño web, soporte tecnico, santa marta, rpa, ti'
      });
    }
    
  }

  ngOnDestroy() {
    if(this.modeHtmlObservable) this.modeHtmlObservable.unsubscribe();
  }

  isBase64(str) {
    if (str ==='' || str.trim() ===''){ return false; }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

}
