import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-desarrollo',
  templateUrl: './desarrollo.component.html',
  styleUrls: ['../servicios.component.scss', './desarrollo.component.scss']
})
export class DesarrolloComponent implements OnInit {

  @Input('mode_html') public mode_html:boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
