import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { AsesoriasComponent } from './servicios/asesorias/asesorias.component';
import { DesarrolloComponent } from './servicios/desarrollo/desarrollo.component';
import { SoporteComponent } from './servicios/soporte/soporte.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { SoftwareComponent } from './software/software.component';
import { NotfoundComponent } from './notfound/notfound.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  // { path: 'home', component: HomeComponent},
  { path: 'contacto', component: ContactoComponent},
  // { path: 'nosotros', component: NosotrosComponent},
  // { path: 'nuestros-productos', component: SoftwareComponent},
  {
    path: 'nuestros-servicios', 
    component: ServiciosComponent,
    children: [
      {
        path: 'asesorias',
        component: AsesoriasComponent
      },
      {
        path: 'desarrollo-software',
        component: DesarrolloComponent
      },
      {
        path: 'soporte-tecnico',
        component: SoporteComponent
      }
    ]
  },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
