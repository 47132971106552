<nav class="navbar fixed-top p-0 navbar-expand-lg navbar-light bg-transparent {{mode_html == true ? 'dark': ''}}">
    <a class="navbar-brand navbar-logo-quality-background pt-3" [routerLink]="['/']">
        <div class="pb-3">
            <object type="image/svg+xml" data="../../assets/img/logo_nombre_negativo.svg" class="navbar-logo-quality">Logo Quality</object>
        </div>
    </a>
    <button class="navbar-toggler" type="button" (click)="bootstrap.openModal(menu_responsive, {size: 'xl', centered: false, windowClass: 'menu-navbar-containt', backdropClass: 'menu-navbar-backdrop'})"><span class="navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
        <div class="navbar-nav ml-auto navbar-quality mr-3">
            <a class="nav-item nav-link" [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">Inicio<span class="sr-only">(current)</span></a>
            <!-- <a class="nav-item nav-link" [routerLink]="['/nosotros']" routerLinkActive="active">Nosotros</a> -->
            <!-- <a class="nav-item nav-link" [routerLink]="['/nuestros-productos']" routerLinkActive="active">Productos</a> -->
            <a class="nav-item nav-link" [routerLink]="['/nuestros-servicios']" routerLinkActive="active">Servicios</a>
            <a class="nav-item nav-link" [routerLink]="['/contacto']" routerLinkActive="active">Contacto</a>
            <a class="nav-item nav-link nav-link-redes" href="https://www.facebook.com/qualitysoftgroup" target="_blank" rel="noreferrer">
                <img type="image/svg+xml" src="../../assets/img/navbar/facebook-icon-dark.svg" class="navbar-redes-sociales" alt="Facebook Qualitysoftgroup" *ngIf="!mode_html">
                <img type="image/svg+xml" src="../../assets/img/navbar/facebook-icon.svg" class="navbar-redes-sociales" alt="Facebook Qualitysoftgroup" *ngIf="mode_html">
            </a>
            <a class="nav-item nav-link nav-link-redes" href="https://www.instagram.com/qualitysoftgroup/" target="_blank" rel="noreferrer">
                <img type="image/svg+xml" src="../../assets/img/navbar/instagram-icon-dark.svg" class="navbar-redes-sociales" alt="Instagram Qualitysoftgroup" *ngIf="!mode_html">
                <img type="image/svg+xml" src="../../assets/img/navbar/instagram-icon.svg" class="navbar-redes-sociales" alt="Instagram Qualitysoftgroup" *ngIf="mode_html">
            </a>
            <a class="nav-item nav-link nav-link-redes" href="https://twitter.com/QualitysoftG" target="_blank" rel="noreferrer">
                <img type="image/svg+xml" src="../../assets/img/navbar/twitter-icon-dark.svg" class="navbar-redes-sociales" alt="Twitter Qualitysoftgroup" *ngIf="!mode_html">
                <img type="image/svg+xml" src="../../assets/img/navbar/twitter-icon.svg" class="navbar-redes-sociales" alt="Twitter Qualitysoftgroup" *ngIf="mode_html">
            </a>
            <a class="nav-item nav-link" (click)="cambiarColor()">
                <fa-icon [icon]="modehtmlIcon"></fa-icon>
            </a>
        </div>
    </div>
</nav>

<!-- Agregar direccion envio-->
<ng-template #menu_responsive let-modal>
    <div class="modal-content modal-general modal-menu-navbar {{mode_html == true ? 'dark': ''}}">
        <button class="btn btn-cerrar" (click)="bootstrap.closeModal(modal)">
            <fa-icon class="icon-close" [icon]="close"></fa-icon>
        </button>
        <div align="center">
            <div class="modal-body">
                <div class="row mt-5 mb-3 justify-content-around">
                    <a class="nav-link-redes" href="https://www.facebook.com/qualitysoftgroup" target="_blank" rel="noreferrer">
                        <img type="image/svg+xml" src="../../assets/img/navbar/facebook-icon.svg" class="navbar-redes-sociales" alt="Facebook Qualitysoftgroup">
                        <p class="text-redes-sociales">Facebook</p>
                    </a>
                    <a class="nav-link-redes" href="https://www.instagram.com/qualitysoftgroup/" target="_blank" rel="noreferrer">
                        <img type="image/svg+xml" src="../../assets/img/navbar/instagram-icon.svg" class="navbar-redes-sociales" alt="Instagram Qualitysoftgroup">
                        <p class="text-redes-sociales">Instagram</p>
                    </a>
                    <a class="nav-link-redes" href="https://twitter.com/QualitysoftG" target="_blank" rel="noreferrer">
                        <img type="image/svg+xml" src="../../assets/img/navbar/twitter-icon.svg" class="navbar-redes-sociales" alt="Twitter Qualitysoftgroup">
                        <p class="text-redes-sociales">Twitter</p>
                    </a>
                </div>
                <div class="row mt-3 mb-3">
                    <div class="swiper-container">
                        <swiper class="swiper-wrapper" [config]="config" [disabled]="disabled">
                            <div class="swiper-slide" *ngFor="let slide of slides">
                                <div (click)="clickSlider(slide, modal)">
                                    <div class="containt-menu">
                                        <img [src]="slide.img" [alt]="slide.alt" class="card-img-top containt-menu-img {{slide.clase}}">
                                        <h2 class="navbar-menu-title">{{slide.titulo}}</h2>
                                        <p class="navbar-menu-text">{{slide.texto}}</p>
                                    </div>
                                </div>
                            </div>
                        </swiper>
                        <!-- Add Pagination -->
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
                <div class="row justify-content-around">
                    <a class="nav-item nav-link iconmodo" (click)="cambiarColor()">
                        <fa-icon [icon]="modehtmlIcon"></fa-icon>
                        <p class="text-redes-sociales">{{modotext}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>
