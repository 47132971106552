import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { faSun, faMoon, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {AppComponent} from '../app.component';
import { BootstrapService } from '../bootstrap.service';
import { NavbarSlides } from './navbar';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  public isCollapsed:boolean = true;
  public mode_html:boolean = true;
  public modotext:string = 'Modo noche';
  private modeHtmlObservable;
  public modehtmlIcon:IconDefinition;
  @ViewChild('menu_responsive', {read: false, static: false}) menu_responsive: TemplateRef<any>;
  public close:IconDefinition = faTimes;


  public slides:Array<NavbarSlides> = [
    {
      img: '../../assets/img/home/nuestros_servicios.svg', 
      alt: 'home', 
      clase:'floating', 
      titulo:'Home', 
      texto:'¡Tu mejor aliado para llegar a la cima!.', 
      ruta: '/'
    },
    // {
    //   img: '../../assets/img/nosotros/notros-qualitysoft-animated.svg', 
    //   alt: 'nosotros', 
    //   clase:'', 
    //   titulo:'Nosotros', 
    //   texto:'Qualitysoft Team', 
    //   ruta: '/nosotros'
    // },
    // {
    //   img: '../../assets/img/productos/productos-qualitysoft-animated.svg', 
    //   alt: 'nuestros-productos', 
    //   clase:'', 
    //   titulo:'Nuestros productos', 
    //   texto:'¡Mira nuestro mas reciente producto Qualitylab!', 
    //   ruta: '/nuestros-productos'
    // },
    {
      img: '../../assets/img/servicios/asesorias-animated.svg', 
      alt: 'nuestros-servicios', 
      clase:'', 
      titulo:'Nuestros servicios', 
      texto:'Asesorias, desarrollo de software, soporte tecnico', 
      ruta: '/nuestros-servicios'
    },
    {
      img: '../../assets/img/contacto/contacto-qualitysoft-animated.svg', 
      alt: 'contacto', 
      clase:'', 
      titulo:'Contacto', 
      texto:'Comunicate con nosotros y obten grandes descuentos', 
      ruta: '/contacto'
    
    }
  ];  
  
  public disabled: boolean = false;
  
  public config: SwiperConfigInterface = {
    slidesPerView: 'auto',
    spaceBetween: 25,
    centeredSlides: true,
    grabCursor: true,
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets'
    },
    flipEffect: {
      slideShadows: false,
    },
  };
  
  constructor(private appfuncion:AppComponent, public bootstrap:BootstrapService, private router:Router) { }
  
  ngOnInit(): void {
    this.mode_html = this.appfuncion.mode_html;
    if(this.mode_html) this.modehtmlIcon = faSun, this.modotext = 'Modo dia';
    if(!this.mode_html) this.modehtmlIcon = faMoon, this.modotext = 'Modo noche';

    this.modeHtmlObservable = this.appfuncion.getmode_html$().subscribe((mode_html:boolean)=>{
      this.mode_html = mode_html;
    });
  }
  
  ngOnDestroy() {
    if(this.modeHtmlObservable) this.modeHtmlObservable.unsubscribe();
  }
  
  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }
  
  cambiarColor(){
    if(!this.mode_html) document.querySelector('body').classList.add('dark'), this.modehtmlIcon = faSun, this.modotext = 'Modo dia';
    if(this.mode_html) document.querySelector('body').classList.remove('dark'), this.modehtmlIcon = faMoon, this.modotext = 'Modo noche';
    this.appfuncion.mode_html = !this.appfuncion.mode_html;
    this.appfuncion.mode_html$.next(this.appfuncion.mode_html);
    localStorage.setItem('mode_html', this.mode_html.toString());
  }

  clickSlider(slideactivo:NavbarSlides, modal){
    this.bootstrap.closeModal(modal);
    this.router.navigate([slideactivo.ruta]);
  }
  
}
