<div class="home-content mt-0 {{mode_html == true ? 'dark': ''}}">
    <div class="home-background">
        <div class="container container-sm container-md container-lg container-xl">
            <!-- Inicio -->
            <div class="row pt-5 mb-md-5 mb-lg-5 mb-xl-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div align="left" class="home-content-text">
                        <h2 class="home-title">¡Tu mejor aliado</h2>
                        <h3 class="home-sub-title">para llegar a la cima!</h3>
                        <p class="mt-4 mb-4 home-descripcion">En Qualitysoft Group te brindamos soluciones informáticas para que tú pequeña, mediana y/o grande empresa se destaque con productividad y rentabilidad en todos tus procesos de negocio.</p>
                        <a class="btn mt-3 mb-3 rounded-pill home-btn-ver-mas" href="https://api.whatsapp.com/send?phone=573006351210&text=Buenas,%20tengo%20una%20gran%20idea%20y%20quiero%20hacerla%20realidad!!!" rel="noreferrer" target="_blank">Contáctanos</a>
                    </div>
                </div>
            </div>
            
            <!-- Nuestros Servicios -->
            <div class="row pt-3 pt-md-5 pt-lg-5 pt-xl-5 mb-1 mb-md-5 mb-lg-5 mb-xl-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center" data-aos="fade-right">
                    <div class="content-nuestros-servicios-quality">
                        <object type="image/svg+xml" data="../../assets/img/home/nuestros_servicios.svg" class="img-nuestros-servicios-quality">Nuestros Servicios</object>
                    </div>
                    <h2 class="nuestros-servicios-title">Nuestros Servicios</h2>
                </div>
            </div>
            <div class="row pt-1 pt-md-5 pt-lg-5 pt-xl-5 mb-5" *ngIf="nuestros_servicios.length > 0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-3 mb-lg-0 mb-xl-0" *ngFor="let servicios of nuestros_servicios">
                    <div class="card text-center bg-transparent border-0">
                        <div class="card-body">
                            <img [src]="servicios.img" [alt]="servicios.img_alt" class="nuestros-servicios-card-img" *ngIf="mode_html">
                            <img [src]="servicios.img_dark" [alt]="servicios.img_alt" class="nuestros-servicios-card-img" *ngIf="!mode_html">
                            <h3 class="nuestros-servicios-card-title mt-3 mb-2">{{servicios.titulo}}</h3>
                            <p class="nuestros-servicios-card-text mt-3 mb-2">{{servicios.descripcion}}</p>
                            <a [routerLink]="['/'+servicios.link]" routerLinkActive="router-link-active"  class="btn nuestros-servicios-btn-ver-mas">Ver más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="home-background-2">
        <div class="container container-sm container-md container-lg container-xl">
            
            <!-- Nuestros productos -->
            <div class="row pt-5 mb-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">
                    <div class="content-nuestros-productos-quality">
                        <object type="image/svg+xml" data="../../assets/img/home/nuestros_productos.svg" class="img-nuestros-productos-quality">nuestros-productos-qualitysoft</object>
                    </div>
                    <h2 class="nuestros-productos-title">Nuestros Productos</h2>
                </div>
            </div>
            <div class="row pt-5  mb-3 mb-md-5 mb-lg-5 mb-xl-5" *ngIf="nuestros_produtos.length > 0">
                <div class="row mt-2 mb-2" *ngFor="let productos of nuestros_produtos">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <img [src]="productos.gif" [alt]="productos.img_alt" class="nuestros-productos-card-img" [routerLink]="['/'+productos.link]" routerLinkActive="router-link-active" >
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                        <a class="nuestros-productos-card-title mt-5 mb-2" [href]="productos.link" rel="noreferrer" target="_blank">{{productos.titulo}}</a>
                        <p class="nuestros-productos-card-text mt-3 mb-2">{{productos.descripcion}}</p>
                    </div>
                </div>
            </div>
            
            <!-- Asociados -->
            <div class="row pt-5 mb-3 mb-md-5 mb-lg-5 mb-xl-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center" data-aos="zoom-in-up">
                    <div class="content-nuestros-asociados-quality">
                        <object type="image/svg+xml" data="../../assets/img/home/asociados.svg" class="img-nuestros-asociados-quality">Asociados</object>
                    </div>
                    <h2 class="nuestros-asociados-title">Asociados</h2>
                </div>
            </div>
            <div class="row pt-1 pt-md-5 pt-lg-5 pt-xl-5" *ngIf="asociados.length > 0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center" *ngFor="let asociado of asociados">
                    <a [href]="asociado.link" rel="noreferrer" target="_blank">
                        <img [src]="asociado.img" [alt]="asociado.img_alt" class="asociados-card-img">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>