import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-soporte',
  templateUrl: './soporte.component.html',
  styleUrls: ['../servicios.component.scss', './soporte.component.scss']
})
export class SoporteComponent implements OnInit {

  @Input('mode_html') public mode_html:boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
