import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import {AppComponent} from '../app.component';
import AOS from 'aos';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {

  public mode_html:boolean = true;
  private modeHtmlObservable:any;

  constructor(private appfuncion:AppComponent, private title:Title, private seo:SeoService) { }

  ngOnInit(): void {
    let t:string = 'Nuestros servicios';
    this.title.setTitle(t);

    this.seo.generateTags({
      title: 'Nuestros servicios',
      description: 'Nuestros servicios',
      image: '../assets/img/servicios/asesorias.svg',
      slug: 'nuestros-servicios',
      keywords: 'servicios qualitysoft group, desarrollo de software, asesorias, diseño web, soporte tecnico, santa marta, rpa, ti'
    });

    this.mode_html = this.appfuncion.mode_html;
    this.modeHtmlObservable = this.appfuncion.getmode_html$().subscribe((mode_html:boolean)=>{
        this.mode_html = mode_html;
    });

    AOS.init();
  }

  ngOnDestroy() {
    if(this.modeHtmlObservable) this.modeHtmlObservable.unsubscribe();
  }

}
