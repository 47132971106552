<div class="{{mode_html == true ? 'dark': ''}}">
    <div class="row mt-md-4 mt-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="servicios-content-text soporte">
                <p class="servicios-subtitle mb-1">El mundo digital</p>
                <h2 class="servicios-title mb-0">ASISTENCIA TÉCNICA</h2>
                <h2 class="servicios-title mb-2">EN TU NEGOCIO</h2>
                <a href="https://api.whatsapp.com/send?phone=573006351210&text=Quisiera%20mejorar%20mi%20infraestructura!!!" rel="noreferrer" target="_blank" class="btn btn-servicios mb-5 mt-2">Cuéntanos</a>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="content-servicios-soporte" align="center" data-aos="zoom-in">
                <object type="image/svg+xml" data="../../assets/img/servicios/soporte.svg" class="img-servicios-soporte">Soporte técnico</object>
            </div>
        </div>
    </div>
    <div class="row mt-5 pb-4">
        <div class="col-12">
            <p class="servicios-text">
                QualitySoft brinda servicio de soporte técnico con el cual su organización contará con personal experto 
                <br>
                para atender sus necesidades de forma presencial o remota.
            </p>
            <p class="servicios-text">Ofrecemos servicios técnicos como: </p>
            <ul class="servicios-text">
                <li>Mantenimiento web.</li>
                <li>Soporte Online.</li>
                <li>Mantenimiento de Equipos.</li>
                <li>Gestión de Correos Electrónicos.</li>
                <li>Redes Informáticas y Servidores.</li>
                <li>Instalación de circuitos cerrado.</li>
            </ul>
            <p class="servicios-text">Ventajas del servicio de soporte técnico e informático.</p>
            <li class="servicios-text">Focalización: su empresa podrá concentrar su atención y sus recursos en el negocio y dejar en nuestros manos el soporte informático, de redes y de software. que es al final lo que apalanca los procesos estratégicos del negocio.</li>
            <li class="servicios-text">Reducción de costos: Es una opción más económica que tener un gran departamento propio de TI y los ahorros varían para cada empresa dependiendo del nivel de tercerización que deseen.</li>
            <!-- <li class="servicios-text">Mejorar la rentabilidad sobre activos: nuestros apoyo le permitirá adquirir soluciones en TI con mayor retorno de  inversión y su empresa obtendrá mayores</li> -->
        </div>
    </div>
</div>