import { Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import { ContactoService } from './contacto.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from'sweetalert2';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  public enviarDisabled:boolean = false;

  public contactFormControl:FormGroup = new FormGroup({
    nombre:  new FormControl('', [Validators.required, Validators.max(255)]),
    correo:  new FormControl('', [Validators.required, Validators.max(255), Validators.email]),
    mensaje:  new FormControl('', [Validators.required, Validators.max(255)])
  });

  constructor(private contactoServices:ContactoService, private title:Title, private seo:SeoService) { }

  ngOnInit(): void {
    let t:string = 'Contacto';
    this.title.setTitle(t);

    this.seo.generateTags({
      title: 'Contacto',
      description: 'Contacto con Qualitysoft Group',
      image: '../assets/img/nosotros/notros-qualitysoft.svg',
      slug: 'contacto',
      keywords: 'contacto qualitysoft group, desarrollo de software, asesorias, diseño web, soporte tecnico, santa marta, rpa, ti'
    });
  }

  enviarFormularioContacto(){
    
    if (!this.contactFormControl.valid){
      return Swal.fire({
        title: 'Campos incompletos',
        icon: 'warning',
        text: 'Por favor completa todos los campos',
        showCancelButton: false,
        confirmButtonColor: '#3935c0',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      });
    }

    this.enviarDisabled = true;

    this.contactoServices.enviarContacto({data: this.contactFormControl.value})
    .subscribe( 
      res =>{
        this.enviarDisabled = false;
        if(res['status'] == 'success'){
          return Swal.fire({
            title: 'Enviada',
            icon: 'success',
            text: 'Ya enviaste la información, nos comunicaremos contigo en unos instantes',
            showCancelButton: false,
            confirmButtonColor: '#3935c0',
            confirmButtonText: 'Aceptar',
            reverseButtons: true
          });
        }else{
          return Swal.fire({
            title: 'Información',
            icon: 'info',
            text: 'Estamos presentando problemas, porfavor intenta más tarde',
            showCancelButton: false,
            confirmButtonColor: '#3935c0',
            confirmButtonText: 'Aceptar',
            reverseButtons: true
          });
        }
      }, 
      err =>{
        this.enviarDisabled = false;
        return Swal.fire({
          title: 'Información',
          icon: 'info',
          text: 'Estamos presentando problemas, porfavor intenta más tarde',
          showCancelButton: false,
          confirmButtonColor: '#3935c0',
          confirmButtonText: 'Aceptar',
          reverseButtons: true
        });
      }
    );
  }

}
