import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-asesorias',
  templateUrl: './asesorias.component.html',
  styleUrls: ['../servicios.component.scss', './asesorias.component.scss']
})
export class AsesoriasComponent implements OnInit {

  @Input('mode_html') public mode_html:boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
