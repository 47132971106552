<div class="container container-sm container-md container-lg container-xl servicios-content">
    <!-- Asesorias -->
    <app-asesorias [mode_html]="mode_html"></app-asesorias>

    <!-- Desarrollo de software -->
    <app-desarrollo [mode_html]="mode_html"></app-desarrollo>

    <!-- Soporte tecnico -->
    <app-soporte [mode_html]="mode_html"></app-soporte>
    <!-- <router-outlet></router-outlet> -->

</div>