import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import AOS from 'aos';
import { SeoService } from '../seo.service';
import {AppComponent} from '../app.component';
import {Servicios, Productos, Asociados} from './home';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  nuestros_servicios:Array<Servicios> = [];
  nuestros_produtos:Array<Productos> = [];
  asociados:Array<Asociados> = [];
  public mode_html:boolean = true;
  private modeHtmlObservable:any;

  constructor(public appfuncion: AppComponent, private title:Title, private seo:SeoService) { }

  ngOnInit(): void {
    
    let t:string = 'Qualitysoft Group';
    this.title.setTitle(t);

    this.seo.generateTags({
      title: 'Qualitysoft Group',
      description: '¡Tu mejor aliado para llegar a la cima!',
      image: '../assets/img/icono.png',
      slug: 'home',
      keywords: 'desarrollo de software, asesorias, diseño web, soporte tecnico, santa marta, rpa, ti'
    });

    this.mode_html = this.appfuncion.mode_html;
    this.modeHtmlObservable = this.appfuncion.getmode_html$().subscribe((mode_html:boolean)=>{
        this.mode_html = mode_html;
    });
    
    AOS.init();

    this.nuestros_servicios.push(
      {
        id: 1, 
        titulo: 'Asesorías', 
        descripcion: 'Brindamos a nuestros clientes una perspectiva crítica y profesional de como pueden mejorar sus proceso de negocios.', 
        img: '../../assets/img/home/nuestros-servicios-asesorias.svg',
        img_dark: '../../assets/img/home/nuestros-servicios-asesorias-dark.svg',
        img_alt: 'asesorías-qualitysoftgroup',
        link: 'nuestros-servicios/asesorias'
      },
      {
        id: 2,
        titulo: 'Desarrollo de software', 
        descripcion: 'Utilizando buenas practicas de desarrollo convertimos las necesidades de nuestros clientes en una virtud.', 
        img: '../../assets/img/home/nuestros-servicios-desarrollo.svg',
        img_dark: '../../assets/img/home/nuestros-servicios-desarrollo-dark.svg',
        img_alt: 'desarrollo-de-software-qualitysoftgroup',
        link: 'nuestros-servicios/desarrollo-software'
      },
      {
        id: 3, 
        titulo: 'Soporte técnico', 
        descripcion: 'Brindamos asistencia técnica para ayudar a nuestros clientes a resolver sus problemas de TI.', 
        img: '../../assets/img/home/nuestros-servicios-soporte.svg',
        img_dark: '../../assets/img/home/nuestros-servicios-soporte-dark.svg',
        img_alt: 'soporte-técnico-qualitysoftgroup',
        link: 'nuestros-servicios/soporte-tecnico'
      }
    );
    this.nuestros_produtos.push(
      {
        id: 1, 
        titulo: 'QualityLab', 
        descripcion: 'Qualitylab es un software sencillo y de fácil uso, para la gestión de resultados de los exámenes de laboratorios microbiologicos. El cual permite registrar a los usuarios para dar ingreso al sistema, asignar permisos y/o roles a dichos usuarios, registrar y administrar pacientes, y generar reportes.', 
        img: '../../assets/img/home/nuestros_productos_1.png',
        img_alt: 'qualitylab-qualitysoftgroup',
        img_negativo: '../../assets/img/home/nuestros_productos_1_negativo.png',
        gif: '../../assets/img/home/nuestros_productos_1_gif.gif',
        gif_negativo: '../../assets/img/home/nuestros_productos_1_gif_negativo.gif',
        // link: 'nuestros-productos'
        link: 'https://qualitysoftgroup.com/qualitylab/index.php'
      }
    );
    this.asociados.push(
      {
        id: 1, 
        titulo: 'Bembelab', 
        img: '../../assets/img/home/asociados_1.svg',
        img_alt: 'bembelab-qualitysoftgroup',
        link: 'https://www.instagram.com/bembelabcom'
      }
    );
  }

  ngOnDestroy() {
    if(this.modeHtmlObservable) this.modeHtmlObservable.unsubscribe();
  }

}
