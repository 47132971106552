<div class="container-sm container-md container-xl contacto-content">
    <div class="row pt-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <object type="image/svg+xml" data="../../assets/img/contacto/contacto1.svg" class="contacto-img-1 align-self-start"></object>
            <object type="image/svg+xml" data="../../assets/img/contacto/carta1.svg" class="contacto-carta-1 align-self-end d-none d-md-none d-lg-block d-xl-block"></object>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" align="center">
            <div class="card card-contacto">
                <div class="card-body">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
                        <h3 class="contacto-title">Contáctanos</h3>
                    </div>
                    <form [formGroup]="contactFormControl" (ngSubmit)="enviarFormularioContacto()">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
                            <input type="text" class="form-control contacto-input" placeholder="Nombre" formControlName="nombre">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
                            <input type="text" class="form-control contacto-input" placeholder="tucorreo@correo.com" formControlName="correo">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
                            <textarea class="form-control contacto-textarea" rows="3" placeholder="Mensaje" formControlName="mensaje"></textarea>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
                            <button class="btn btn-contacto mt-5" type="submit" [disabled]="enviarDisabled">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <object type="image/svg+xml" data="../../assets/img/contacto/carta2.svg" class="contacto-carta-2 align-self-start d-none d-md-none d-lg-block d-xl-block"></object>
            <object type="image/svg+xml" data="../../assets/img/contacto/contacto3.svg" class="contacto-img-2 pt-sm-0 pt-md-1 align-self-end align-self-md-start align-self-sm-start"></object>
        </div>
    </div>
</div>