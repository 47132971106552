<div class="{{mode_html == true ? 'dark': ''}} mt-5 pt-5">
    <div class="row mt-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="servicios-content-text">
                <p class="servicios-subtitle mb-1">Añade a tu negocio</p>
                <h2 class="servicios-title mb-0">Una perspectiva</h2>
                <h2 class="servicios-title mb-0">crítica, objetiva</h2>
                <h2 class="servicios-title mb-2">y profesional</h2>
                <a href="https://api.whatsapp.com/send?phone=573006351210&text=Buenas,%20quisiera%20reservar%20una%20asesor%C3%ADa%20con%20el%20team%20quality!!!" rel="noreferrer" target="_blank" class="btn btn-servicios mb-5 mt-2">RESERVA</a>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="content-servicios-asesoria" align="center" data-aos="flip-left">
                <object type="image/svg+xml" data="../../assets/img/servicios/asesorias.svg" class="img-servicios-asesoria">Asesorias</object>
            </div>
        </div>
    </div>
    <div class="row mt-5 pb-4">
        <div class="col-12">
            <p class="servicios-text">
                QualitySoft Group brindamos asesorías tecnológicas con herramientas a la medida y un enfoque de aplicación amigable con el usuario teniendo en cuenta las necesidades del cliente y las ventajas que proporciona invertir en tecnologías de calidad que contribuyen en la estrategia y objetivos de su empresa para mejorar sus procesos de negocio.
                <br>
                Contamos con un equipo de ingenieros expertos en desarrollo web, aplicaciones móviles, diseño y RPA.
            </p> 
            <p class="servicios-text">Analizamos a detalle los procesos y las actividades que su organización ejecuta para brindar una solución a la medida que encaje perfectamente con las proyecciones de la empresa, que aporten ventajas económicas y una alta escalabilidad.</p> 
            <p class="servicios-text">Nos enfocamos en brindar soluciones que reduzcan costos de operación a la organización teniendo en cuenta que no todo es netamente tecnológico también se debe proyectar los beneficios que la aplicación de nuevas tecnologías acarrea.</p>
            <p class="servicios-text">Impulsamos la competitividad de su empresa buscando soluciones que maximicen la productividad y la calidad del servicio que ofrecen.</p> 
        </div>
    </div>
</div>