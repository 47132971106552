import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  private baseUrl:string = 'https://qualitysoftgroup.com/qualitylab'

  constructor(private http:HttpClient) { }

  enviarContacto(data){
    return this.http.post(`${this.baseUrl}/controller/contactoController.php?contacto`, data);
  }
}
