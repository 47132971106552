<div class="container container-sm container-md container-lg container-xl productos-content {{mode_html == true ? 'dark': ''}}">
    <div class="row mt-5 pt-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="productos-content-video">
                <iframe src="https://www.youtube.com/embed/BjhW3vBA1QU?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="productos-content-img d-flex justify-content-center">
                <object type="image/svg+xml" data="'../../assets/img/productos/productos-qualitysoft.svg" class="productos-content-img">Nuestros Productos</object>
            </div>
        </div>
    </div>
    <div class="row mt-5 pt-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
            <a class="productos-title" href="http://qualitysoftgroup.com/qualitylab" target="_blank" rel="noreferrer">Qualitylab</a>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="productos-text">Qualitylab es un productos sencillo y de fácil uso, para la gestión de resultados de los exámenes de laboratorios microbiologicos. El cual permite registrar a los usuarios para dar ingreso al sistema, asignar permisos y/o roles a dichos usuarios, registrar y administrar pacientes, y generar reportes.</p>
            <p class="productos-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat aspernatur cumque vero molestias tempora ad? Consectetur corrupti, eius autem vel, sit illum unde, culpa eum illo eaque ab a nostrum?</p>
            <p class="productos-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat aspernatur cumque vero molestias tempora ad? Consectetur corrupti, eius autem vel, sit illum unde, culpa eum illo eaque ab a nostrum?</p>
            <p class="productos-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat aspernatur cumque vero molestias tempora ad? Consectetur corrupti, eius autem vel, sit illum unde, culpa eum illo eaque ab a nostrum?</p>
        </div>
    </div>
</div>