<div class="{{mode_html == true ? 'dark': ''}}">
    <div class="row mt-md-4 mt-5">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="servicios-content-text">
                <p class="servicios-subtitle mb-1">El mundo digital</p>
                <h2 class="servicios-title mb-0">Transforma tu negocio</h2>
                <h2 class="servicios-title mb-2">y atrae más clientes</h2>
                <a href="https://api.whatsapp.com/send?phone=573006351210&text=Como%20te%20venia%20contando..." rel="noreferrer" target="_blank" class="btn btn-servicios mb-5 mt-2">Cuéntanos</a>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="content-servicios-desarrollo" align="center" data-aos="flip-up">
                <object type="image/svg+xml" data="../../assets/img/servicios/desarrollo.svg" class="img-servicios-desarrollo">Desarrollo de software</object>
            </div>
        </div>
    </div>
    <div class="row pb-lg-4">
        <div class="col-12">
            <p class="servicios-text">Basados en estándares y buenas practicas de desarrollo brindamos a nuestros clientes productos de calidad y de manera oportuna, comprometidos con el cumplimiento de los tiempos estipulados y la normativa requerida para la creación de un producto software.</p>
            <p class="servicios-text">QualitySoft Group utiliza las mejores tecnologías en el desarrollo de sus aplicaciones web, móviles y RPA, para poder potenciar sus procesos con la implementación de TI y automatización de los mismos, garantizando eficiencia y eficacia.</p>
            <p class="servicios-text">Nuestro apoyo garantiza</p>
            <ul class="servicios-text">
                <li>Cumplimiento de requisitos funcionales y técnicos</li>
                <li>Implementación de aplicaciones sin frenar la operación de la empresa</li>
                <li>Capacitación al usuario final</li>
            </ul>
            <p class="servicios-text"> El personal de QualitySoft Group trabaja en completa coordinación con el cliente para alcanzar objetivos fijados con la mayor precisión.</p>
        </div>
    </div>
</div>