import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  
  public mode_html:boolean = localStorage.getItem('mode_html') == null ? true : localStorage.getItem('mode_html') == 'true' ? true : false;
  mode_html$ = new Subject<Boolean>();
  title = 'qualitysoft';

  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  getmode_html$(): Observable<Boolean> {
    if(this.mode_html) document.querySelector('body').classList.add('dark');
    if(!this.mode_html) document.querySelector('body').classList.remove('dark');
    return this.mode_html$.asObservable();
  }
}
