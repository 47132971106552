<div class="nosotros-content pt-5 pb-5 {{mode_html == true ? 'dark': ''}}">
    <div class="container container-sm container-md container-lg container-xl">
        <div class="row mt-5 pt-5 mb-5">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">
                <div class="content-nosotros-quality">
                    <object type="image/svg+xml" data="../../assets/img/nosotros/notros-qualitysoft.svg" class="img-nosotros-quality">qualitysoft-team</object>
                </div>
                <h1 class="nosotros-title">Qualitysoft Team</h1>
            </div>
        </div>
        <div class="row mt-1 pt-1 mt-sm-1 pt-sm-1 mt-md-1 pt-md-1 mt-lg-5 pt-lg-5 mt-xl-5 pt-xl-5 pb-4">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p class="nosotros-text">
                    QualitySoft Group brindamos asesorías tecnológicas con herramientas a la medida y un enfoque de aplicación amigable con el usuario teniendo en cuenta las necesidades del cliente y las ventajas que proporciona invertir en tecnologías de calidad que contribuyen en la estrategia y objetivos de su empresa para mejorar sus procesos de negocio.
                    <br>
                    Contamos con un equipo de ingenieros expertos en desarrollo web, aplicaciones móviles, diseño y RPA.
                </p> 
                <p class="nosotros-text">Analizamos a detalle los procesos y las actividades que su organización ejecuta para brindar una solución a la medida que encaje perfectamente con las proyecciones de la empresa, que aporten ventajas económicas y una alta escalabilidad.</p> 
                <p class="nosotros-text">Nos enfocamos en brindar soluciones que reduzcan costos de operación a la organización teniendo en cuenta que no todo es netamente tecnológico también se debe proyectar los beneficios que la aplicación de nuevas tecnologías acarrea.</p>
                <p class="nosotros-text">Impulsamos la competitividad de su empresa buscando soluciones que maximicen la productividad y la calidad del servicio que ofrecen.</p> 
            </div>
        </div>
        <div class="row mt-5 pt-5 pb-4">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center">
                <h2 class="nosotros-carateristicas-title">Características especiales</h2>
            </div>
        </div>
        <div class="row mt-5 pt-5">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card card-nosotros-quality mb-3 mb-sm-3 mb-md-3 m-lg-5 m-xl-5">
                    <div class="card-body" align="center">
                        <fa-icon class="card-icon" [icon]="nosotrosIcon" size="7x"></fa-icon>
                        <p class="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card card-nosotros-quality mb-3 mb-sm-3 mb-md-3 m-lg-5 m-xl-5">
                    <div class="card-body" align="center">
                        <fa-icon class="card-icon" [icon]="nosotrosIcon" size="7x"></fa-icon>
                        <p class="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card card-nosotros-quality mb-3 mb-sm-3 mb-md-3 m-lg-5 m-xl-5">
                    <div class="card-body" align="center">
                        <fa-icon class="card-icon" [icon]="nosotrosIcon" size="7x"></fa-icon>
                        <p class="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pb-4">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card card-nosotros-quality mb-3 mb-sm-3 mb-md-3 m-lg-5 m-xl-5">
                    <div class="card-body" align="center">
                        <fa-icon class="card-icon" [icon]="nosotrosIcon" size="7x"></fa-icon>
                        <p class="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card card-nosotros-quality mb-3 mb-sm-3 mb-md-3 m-lg-5 m-xl-5">
                    <div class="card-body" align="center">
                        <fa-icon class="card-icon" [icon]="nosotrosIcon" size="7x"></fa-icon>
                        <p class="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card card-nosotros-quality mb-3 mb-sm-3 mb-md-3 m-lg-5 m-xl-5">
                    <div class="card-body" align="center">
                        <fa-icon class="card-icon" [icon]="nosotrosIcon" size="7x"></fa-icon>
                        <p class="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5 pt-5 mb-5" *ngIf="nosotros.length > 0">
            <div class="d-block d-sm-block d-md-block d-lg-flex d-xl-flex justify-content-around">
                <div class="nosotros-content-img mb-5" *ngFor="let us of nosotros">
                    <img [src]="us.img" [alt]="us.nombre+' '+us.apellido"class="nosotros-img">
                </div>
            </div>
        </div>
    </div>
</div>