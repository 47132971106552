import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import {AppComponent} from '../app.component';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Nosotros } from './nosotros';


@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})

export class NosotrosComponent implements OnInit {
  public nosotros:Array<Nosotros>;
  public nosotros_actual:Nosotros = null;
  public mode_html:boolean = true;
  private modeHtmlObservable:any;
  public nosotrosIcon = faHeart;


  constructor(public appfuncion: AppComponent, private title:Title, private seo:SeoService) { }

  ngOnInit(): void {

    let t:string = 'Nosotros';
    this.title.setTitle(t);

    this.seo.generateTags({
      title: 'Nosotros',
      description: 'Nosotros',
      image: '../assets/img/nosotros/notros-qualitysoft.svg',
      slug: 'nosotros',
      keywords: 'nosotros qualitysoft group, desarrollo de software, asesorias, diseño web, soporte tecnico, santa marta, rpa, ti'
    });
    
    this.mode_html = this.appfuncion.mode_html;
    this.modeHtmlObservable = this.appfuncion.getmode_html$().subscribe((mode_html:boolean)=>{
        this.mode_html = mode_html;
    });

    this.nosotros = [
      {
        nombre: 'Alejandro',
        apellido: 'Araque',
        descripcion: 'Delantero',
        img: '../../assets/img/nosotros/nosotros-3.svg'
      },
      {
        nombre: 'Cristhian',
        apellido: 'Carpio',
        descripcion: 'Centrocampista',
        img: '../../assets/img/nosotros/nosotros-2.svg'
      },
      {
        nombre: 'Efrain',
        apellido: 'Garcia',
        descripcion: 'Mediocampista',
        img: '../../assets/img/nosotros/nosotros-3.svg'
      },
      {
        nombre: 'Juan',
        apellido: 'Gutierrez',
        descripcion: 'Defensa',
        img: '../../assets/img/nosotros/nosotros-1.svg'
      },
      {
        nombre: 'Juan',
        apellido: 'Rodriguez',
        descripcion: 'Arquero',
        img: '../../assets/img/nosotros/nosotros-2.svg'
      }
    ];
    this.nosotros_actual = this.nosotros[0];
  }

  ngOnDestroy() {
    if(this.modeHtmlObservable) this.modeHtmlObservable.unsubscribe();
  }

}
