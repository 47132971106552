import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../app.component';
import { faHeart } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  test : Date = new Date();
  public mode_html:boolean = true;
  private modeHtmlObservable:any;
  public footerIcon = faHeart;

  constructor(public appfuncion: AppComponent) { }

  ngOnInit(): void {
    this.mode_html = this.appfuncion.mode_html;
    this.modeHtmlObservable = this.appfuncion.getmode_html$().subscribe((mode_html:boolean)=>{
        this.mode_html = mode_html;
    });
  }
  
  ngOnDestroy() {
    if(this.modeHtmlObservable) this.modeHtmlObservable.unsubscribe();
  }
}
